"use client";
import React from "react";
import dynamic from "next/dynamic";
import Link from "next/link";
import { Useapi } from "@/helpers/apiContext";
import Image from "next/image";
import NavbarItems from "@/components/CommonComponent/Navbar-items";

// Dynamically import the mobile menu with a fallback
const SheetSide = dynamic(
  () => import("@/components/CommonComponent/Mobilemenu"),
  { ssr: false, loading: () => <div className="loader"></div> }
);
const Navbar: React.FC = () => {
  const { basic_details } = Useapi();
  return (
    <div className="text-homeblack py-3 lg:py-0 sticky shadow-md -top-2 z-30 bg-white">
      <div className="w-full px-4 xl:w-[95%] 2xl:w-[80%] mx-auto">
        <div className="flex w-full justify-between items-center">
          <div className="w-fit">
            <Link href="/" aria-label="Go to Home Page" title="Go to Home Page">
              <Image
                src="/images/logo.png"
                width={137}
                height={58}
                loading="lazy"
                fetchPriority="high"
                alt={basic_details?.basic_details[0]?.logo_alt || "W3era Logo"}
                className="cursor-pointer"
              />
            </Link>
          </div>
          <div className="hidden w-full lg:block">
            <NavbarItems show={0} />
          </div>
          <div className="text-[26px] lg:hidden cursor-pointer">
            <SheetSide />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
